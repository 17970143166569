.table-container {
  :global {
    .ant-table-wrapper .ant-table .ant-table-cell {
      background-color: inherit !important;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: none !important;
    }
  }
}
