.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  text-shadow: none !important;
  border-radius: 0.8rem;
  border: 1px solid #c9cccf !important;
  min-height: 4rem;
}
