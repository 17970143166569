.btn_delete {
  border: none !important;
  :global {
    .ant-btn {
      border: none !important;
    }
  }
}
.errorField {
  color: var(--red_5);
}
