// ::-webkit-scrollbar {
//   // -webkit-appearance: none;
//   height: 10px !important;
//   width: 5px !important;
// }

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c1c1c1;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
