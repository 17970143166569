.ant-message {
  position: absolute;
  bottom: 20px !important;
  top: unset !important;

  &-notice-content {
    padding: 0;
  }

  &-custom-content {
    padding: 10px 16px;
    background: theme('colors.green.DEFAULT');
    border-radius: 12px;
    color: white;
    font-weight: 800;
  }

  &-success {
    background: theme('colors.green.DEFAULT');
  }

  &-error {
    background: theme('colors.red.dark');
  }

  .anticon {
    display: none;
  }
}