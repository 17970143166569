@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap");
//Sora

@font-face {
  font-family: "Sora-Bold";
  src: url("./Font/Sora/Sora-Bold.ttf");
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sora-ExtraBold";
  src: url("./Font/Sora/Sora-ExtraBold.ttf");
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sora-ExtraLight";
  src: url("./Font/Sora/Sora-ExtraLight.ttf");
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sora-Light";
  src: url("./Font/Sora/Sora-Light.ttf");
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sora-Medium";
  src: url("./Font/Sora/Sora-Medium.ttf");
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sora-Regular";
  src: url("./Font/Sora/Sora-Regular.ttf");
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sora-SemiBold";
  src: url("./Font/Sora/Sora-SemiBold.ttf");
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sora-Thin";
  src: url("./Font/Sora/Sora-Thin.ttf");
  font-style: normal;
  font-stretch: normal;
}
