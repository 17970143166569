.description-textarea {
  border: 1px solid var(--gray_6);
  padding: var(--sp_lv_2) var(--sp_lv_4);
  height: 150px;
  margin-top: var(--sp_lv_1);
  border-radius: var(--sp_lv_1);
}
.error_message {
  color: var(--red_5);
}
