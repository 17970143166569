.ant-radio-group {
  .ant-radio-wrapper {
    padding: 1.5rem 0;
    margin-right: 14px;

    span {
      font-size: 1.4rem;
    }
  }
  .ant-radio-inner {
    border: 2px solid theme("colors.primary");
    &::after {
      content: url(../../../../Assets/Images/SVG/check.svg);
      font-size: 20px;
      line-height: 16px;
      transform: scale(1.1) !important;
      text-align: center;
    }
  }
}
