.imageVariant {
  :global {
    .ant-upload-list-picture-card-container {
      width: 50px !important;
      height: 50px !important;
      margin: 0 !important;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
      padding: 0 !important;
    }
  }
}
