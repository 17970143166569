.subcategory_container {
  background: var(--gray_1);
  border-radius: var(--br_lg);
  padding-bottom: var(--sp_lv_6);
  .heading {
    padding: var(--sp_lv_6);
    padding-bottom: var(--sp_lv_0);
    h2 {
      margin-bottom: var(--sp_lv_4);
    }
  }

  .btn_add_subcategory_container {
    padding: 0 var(--sp_lv_6) 0;
    .btn_add_subcategory {
      width: 100%;
      color: var(--blue_7);
      justify-content: flex-start;
      border: none !important;
      padding: var(--sp_lv_0);
    }
    .btn_add_subcategory:hover {
      color: var(--blue_8);
    }
  }
}
