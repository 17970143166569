.dropdown_box {
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  &.hover {
    background-color: transparent;
  }
  :global {
    .ant-dropdown ant-dropdown-placement-bottomLeft {
      min-width: 0 !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .hide {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .hide {
    display: none;
  }
}
