.form_edit_variant_container {
  .edit_variant_fields {
    padding: var(--sp_lv_4) var(--sp_lv_8);
    .input_number_addAll {
      width: 100%;
    }
    .input_number {
      width: 100%;
    }
    .error_message {
      font-size: 1.2rem;
      color: var(--red_5);
    }
  }
  .button_submit_form {
    padding: 0 var(--sp_lv_8) var(--sp_lv_8);
  }
}
