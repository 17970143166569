//DEFINED FONT SIZE

h1 {
  font-family: "Sora-Bold";
  font-size: 2.4rem;
}

h2 {
  font-family: "Sora-SemiBold";
  font-size: 1.8rem;
}

h3 {
  font-family: "Sora-SemiBold";
  font-size: 1.6rem;
}

h4 {
  font-family: "Sora-SemiBold";
  font-size: 1.3rem;
}

h5 {
  font-size: 1.1rem;
  font-family: "Sora-SemiBold";
}

p {
  font-family: "Sora-Regular";
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
}

a {
  font-family: "Sora-Light";
  list-style: none;
  font-size: 1.4rem;
}

span {
  font-size: 1.4rem;
  font-family: "Sora-Regular";
}

th {
  font-family: "Sora-SemiBold";
  font-size: 1.4rem;
}

@media screen and (min-width: 1024px) {
  h1 {
    font-family: "Sora-Bold";
    font-size: 3rem;
  }

  h2 {
    font-family: "Sora-SemiBold";
    font-size: 2rem;
  }

  h3 {
    font-family: "Sora-SemiBold";
    font-size: 1.8rem;
  }

  h4 {
    font-family: "Sora-SemiBold";
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.2rem;
    font-family: "Sora-SemiBold";
  }

  p {
    font-family: "Sora-Regular";
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
  }

  a {
    font-family: "Sora-Light";
    list-style: none;
    font-size: 1.4rem;
  }

  span {
    font-size: 1.4rem;
    font-family: "Sora-Regular";
  }

  th {
    font-family: "Sora-SemiBold";
    font-size: 1.4rem;
  }
}
