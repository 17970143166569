@tailwind components;
@tailwind utilities;

@import "./tailwindbase.scss";
@import "antd/dist/antd.css";
@import "./_font";
@import "./CustomStyles/Antd/styles.scss";
@import "./Own/scrollbar.scss";
@import "./Own/font.scss";
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");

#root {
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: "Sora-Regular";
}

html {
  font-size: 10px;
}

body {
  font-size: 1.4rem;
  font-style: normal;
  font-family: "Sora", sans-serif;
}
div {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
}

input,
button,
select,
optgroup,
textarea {
  outline: none !important;
}
/// new
