.custom-checkbox {
  display: flex !important;
  align-items: center !important;
  :global {
    .ant-checkbox {
      border: 2px solid #3e64ff !important;
      border-radius: 4px !important;
      overflow: hidden;
      top: 0 !important;
      .ant-checkbox-inner {
        border: none !important;
      }
    }
  }
}
