.variants_product_container {
  background: var(--gray_1);
  border-radius: var(--br_lg);
  padding: var(--sp_lv_6);
  .heading_variants {
    padding-bottom: var(--sp_lv_0);
    h2 {
      margin-bottom: var(--sp_lv_4);
    }
  }
}
