.ant-input:not(.ant-input[type="password"]) {
  min-height: 4rem;
  padding: 1rem;
  border-radius: 24px;
  background-color: theme("colors.white.DEFAULT");
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: theme("colors.black.DEFAULT");
  border: 1px solid #c9cccf;

  &:focus {
    background-color: theme("colors.white.DEFAULT");
  }
}

.ant-form-item-control-input {
  min-height: 1px;
}

.ant-input-password {
  min-height: 4rem !important;
  padding: 0 12px 0 0;
  border-radius: 24px;
  // background-color: theme("colors.gray.lighter") !important;
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: theme("colors.black.DEFAULT");
  // border-color: transparent;
  border: 1px solid #e6e6e6;
  .ant-input {
    // padding: calc(1rem - 11px) calc(2rem - 11px) !important;
    background-color: transparent;
    // height: 4.5rem;
    padding-left: 2rem !important;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }

  input {
    background: transparent !important;
  }
}
.ant-input-affix-wrapper {
  padding-top: 0 !important;
  // background-color: theme("colors.gray.lighter") !important;
  padding-bottom: 0 !important;
  .ant-input {
    background-color: transparent;
  }
  // border: none;
}

// .ant-input-group {
//   input {
//     .ant-form-item-has-error
//       :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
//       border: 1px solid #ff4d4f !important;
//     }
//   }
// }

.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ) {
  .ant-input-affix-wrapper,
  .ant-input,
  .ant-input-number,
  .ant-picker,
  .ant-select-selector {
    border: 1px solid #ff4d4f;
    background-color: theme("colors.white.DEFAULT");

    &:focus {
      box-shadow: none !important;
    }
  }

  .ant-input-number .ant-input-number-input {
    border: 1px solid #ff4d4f;
    background-color: theme("colors.white.DEFAULT");
  }
}

.ant-input-affix-wrapper-focused {
  background-color: theme("colors.white.DEFAULT");
}
// .ant-input-group {
//   position: relative;
//   input {
//     border-top-right-radius: 24pxem !important;
//     border-bottom-right-radius: 24pxem !important;
//   }
// }

.ant-input-group-wrapper {
  .ant-input-group-addon {
    border: none;
    padding: 0 18px;
    background-color: theme("colors.gray.DEFAULT");
    border-radius: 24px;

    &:last-child {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }
  }

  .ant-input {
    width: 103% !important;
  }
}

.ant-input-number {
  min-height: 4rem !important;
  padding: 1rem 2rem;
  border-radius: 24px;
  background-color: theme("colors.gray.lighter");
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: theme("colors.black.DEFAULT");
  border: none !important;

  // &:focus {
  //   border-color: theme("colors.blue.DEFAULT");
  //   background-color: theme("colors.white.DEFAULT");
  // }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input-wrap {
    .ant-input-number-input {
      height: auto;
      padding: 1rem 2rem;
      border-radius: 24pxem;
      background-color: transparent;
      font-size: 1.4rem;
      line-height: 1.4rem;
      color: theme("colors.black.DEFAULT");

      // &:focus {
      //   border-color: theme("colors.blue.DEFAULT");
      //   background-color: transparent;
      // }
    }
  }
}

// .ant-input-number-focused {
//   border-color: theme("colors.blue.DEFAULT");
//   background-color: theme("colors.white.DEFAULT");
// }
