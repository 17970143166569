.inputSearch {
  border: 1px solid var(--gray_5) !important;
  max-width: 25%;
  min-width: 150px;
  border-radius: 24px;
  :global {
    .ant-input {
      border: none !important;
      min-height: 40px !important;
    }
  }
}
