.pricing_container {
  background: var(--gray_1);
  border-radius: var(--br_lg);
  padding: var(--sp_lv_6);

  .input_number {
    width: 100%;
  }
  .error_message {
    color: var(--red_5);
  }
}
