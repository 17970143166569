.signIn-Page-container {
  height: 100vh;
  .form-signIn-container {
    padding: var(--sp_lv_8);
    border-radius: var(--sp_lv_1);
    background-color: var(--gray_2);
    min-width: 500px;
    .error_message {
      color: var(--red_6);
    }
  }
}
