.ant-input-number {
  min-height: 4rem !important;
  padding: 0;
  background-color: theme("colors.white.DEFAULT");
  font-size: 1.4rem;
  line-height: 1.4rem;
  border-radius: 1rem;
  border: 1px solid #c9cccf;

  color: theme("colors.black.DEFAULT");

  &:focus {
    border-color: theme("colors.blue.DEFAULT");
    background-color: theme("colors.white.DEFAULT");
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input-wrap {
    min-height: 4rem;

    .ant-input-number-input {
      border-radius: 1rem;
      min-height: 4rem;
      height: auto;
      padding: 0 2rem;
      border: 1px solid #c9cccf;
    }
  }
}

.ant-input-number-focused {
  border-color: theme("colors.blue.DEFAULT");
  background-color: theme("colors.white.DEFAULT");
}
