.sider-container {
  background-color: var(--white);
  overflow: auto;
  // position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;

  margin-top: var(--sp_lv_6);
  margin-bottom: var(--sp_lv_6);
  margin-left: 10px;
  padding: var(--sp_lv_6) var(--sp_lv_3);
  border-radius: var(--br_md);

  box-shadow: 0px 3px 27px -17px rgba(28, 28, 28, 0.59);
  -webkit-box-shadow: 0px 3px 27px -17px rgba(28, 28, 28, 0.59);
  -moz-box-shadow: 0px 3px 27px -17px rgba(28, 28, 28, 0.59);

  :global {
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      border-radius: var(--br_md);
    }
    .ant-menu-title-content {
      display: flex;
    }
  }

  .menu-wrapper {
    margin: var(--sp_lv_5) 0 var(--sp_lv_5);
    padding: var(--sp_lv_3) 0;
    border-top: 1px solid var(--divider);
    border-bottom: 2px solid var(--divider);
  }
}
