:global {
  .ant-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.empty-data-title {
  color: theme('colors.black.dark');
  font-size: 1.6rem;
}
