.image-container {
  :global {
    .ant-image {
      overflow: hidden;
    }
    .ant-image-img {
      object-fit: cover !important;
    }
  }
}
