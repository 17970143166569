.table-container {
  background-color: theme("colors.white.DEFAULT");
  padding: 24px;
  border-radius: 12px;
  :global {
    .ant-table.ant-table-small .ant-table-tbody > tr > td {
      padding: 8px 10px !important;
    }
    .ant-table-container {
      margin-top: 24px;
      border: 1px solid #dedede;
      border-radius: 24px;
      .ant-table-header {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        .ant-table-cell {
          min-height: 5rem !important;
          padding: 24px 14px !important;
          font-size: 1.4rem;
          color: #8c8c8c;
          font-weight: 800;

          &::before {
            width: 0 !important;
          }
        }
      }
    }
    .ant-table-wrapper .ant-table .ant-table-row:last-child .ant-table-cell {
      border: 0;
    }
  }
}
