.form-customer-container {
  margin: var(--sp_lv_8);
  border-radius: var(--sp_lv_1);
  background: var(--gray_1);
  .input {
    border: 1px solid var(--gray_6);
    padding: var(--sp_lv_2) var(--sp_lv_4);
    margin-top: var(--sp_lv_1);
    border-radius: var(--sp_lv_1);
  }
  .description-textarea {
    border: 1px solid var(--gray_6);
    padding: var(--sp_lv_2) var(--sp_lv_4);
    height: 200px;
    margin-top: var(--sp_lv_1);
    border-radius: var(--sp_lv_1);
  }
  .error_message {
    color: var(--red_6);
  }
}
