.upload_image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: var(--sp_lv_1);
  .ant-upload-list-picture-card .ant-upload-list-item-actions {
    display: flex;
    align-items: center;
    .ant-btn {
      border: none !important;
    }
  }
}
