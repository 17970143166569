.ant-select {
  display: flex;
  align-items: center;
  min-height: 4rem;
  border-radius: 1rem;
  background-color: theme("colors.gray.lighter");
  font-size: 1.4rem;
  color: theme("colors.black.DEFAULT") !important;
}

.ant-select-selector {
  width: 100%;
  height: auto;
  min-height: 4rem;
  border-radius: 1rem !important;
  border: 1px solid theme("colors.gray.border");
  // padding: 0 2rem !important;
  // background-color: theme('colors.gray.lighter') !important;
  align-items: center;
}

.ant-select-selection-search-input {
  min-height: 4rem;
}

.ant-select-arrow {
  color: theme("colors.primary");
  font-size: 1rem;
}

.ant-select-suffix {
  color: theme("colors.primary");
}

.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #ff4f4f !important;
  background: theme("colors.white.DEFAULT") !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 1 !important;
  display: flex;
}
