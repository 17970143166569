.ant-form-item-label {
  max-height: 3.4rem;
  padding: 0;

  label {
    font-family: 'Sora-SemiBold';
    font-size: 1.3rem;
    line-height: 1.4rem;
    margin-left: 0.3rem;
    max-height: 3.4rem;
  }
}

.ant-col.ant-col-24.ant-form-item-label {
  max-height: 3.4rem;
  padding: 0;
}
.ant-col-24.ant-form-item-label {
  padding-bottom: 1.2rem !important;
}
.ant-form-item {
  padding-bottom: 1.5rem !important;
  margin-bottom: 0rem !important;
}

.ant-form-item-required {
  max-height: 1.2rem;
}

.ant-form-item-control-input {
  min-height: 1px !important;
}

.ant-form-item-control {
  flex-direction: column-reverse;
}

.ant-form-item-explain {
  display: flex;
  justify-content: flex-end;
}

form div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
  display: none;
}

@media screen and (max-width: 500px) {
  .ant-form-item-explain {
    position: unset !important;
  }
}
